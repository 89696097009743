import React, { useState } from "react";
import { Link } from "gatsby";
import cn from "classnames";
import cookie from "js-cookie";
import Helmet from "react-helmet";

import MobileNav from "./MobileNav";

import Logo from "../images/inlinesvgs/logo.svg";
import IconApi from "../images/inlinesvgs/icons/api.svg";
import IconEmail from "../images/inlinesvgs/icons/email.svg";
import IconPanak from "../images/inlinesvgs/icons/panak.svg";
import IconRaketa from "../images/inlinesvgs/icons/raketa.svg";
import IconSipka from "../images/inlinesvgs/icons/sipka.svg";
import IconsZavinac from "../images/inlinesvgs/icons/zavinac.svg";
import IconEditor from "../images/inlinesvgs/icons/editor.svg";
import IconTriangleDown from "../images/inlinesvgs/icons/triangle-down.svg";
import IconArrowRight from "../images/inlinesvgs/icons/arrow-right.svg";

const NavDropdown = ({ label, children, className }) => {
	const [isOpen, setOpen] = useState(false);

	return (
		<span
			className="dropdownWrapper"
			style={{ display: "inline-block" }}
			onMouseEnter={() => setOpen(true)}
			onMouseLeave={() => setOpen(false)}>
			<button className="nav-link" onClick={() => setOpen(true)}>
				<span className="text-small text-mediumDark nav-linkLabel">
					<span>{label}</span>
					<IconTriangleDown />
				</span>
			</button>
			{isOpen && <span className="dropdownWrapper-hoverHelper" />}
			<div className={cn(className, "dropdown", { "is-open": isOpen })}>
				{children}
			</div>
		</span>
	);
};

const NavDropdownItem = ({ children, url }) => {
	return (
		<Link className="dropdown-linkItem" to={url}>
			{children}
		</Link>
	);
};

const NavDropdownItemWithIcon = ({ icon, label, description, url }) => {
	return (
		<Link className="dropdown-navItem" to={url}>
			<span className="dropdown-navItemIcon">{icon}</span>
			<span className="dropdown-navItemLabel">{label}</span>
			<span className="dropdown-navItemDescription">{description}</span>
		</Link>
	);
};

const DiscountBar = ({ discountRef = cookie.get("discount-ref") }) => {
	// Kurva-fix protoze Gatsby nefunguje SSR a jinak chyby trida `headerIntroBar`
	const [isClient, setClient] = useState(false);
	React.useEffect(() => {
		setClient(true);
	}, []);

	if (!discountRef) return null;

	return (
		<div className="headerIntroBar" key={isClient}>
			<span className="tag ">-50%</span>
			<div>Yay! Your celebration discount is applied.</div>
		</div>
	);
};

const Header = ({ discountRef }) => {
	const userJson = cookie.get("user");
	const user = userJson && JSON.parse(userJson);

	return (
		<header className="header">
			<Helmet>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900;1,100..900&display=swap"
				/>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap"
				/>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/css2?family=Oxygen+Mono&display=swap"
				/>
				<link rel="preconnect" href="https://fonts.googleapis.com" />,
				<link
					rel="preconnect"
					href="https://fonts.gstatic.com"
					crossOrigin="anonymous"
				/>
			</Helmet>

			<DiscountBar discountRef={discountRef} />

			<div className="container">
				<nav className="nav">
					<Link to="/">
						<Logo className="logo" width={140} />
					</Link>

					<div className="nav-pages">
						<NavDropdown label="Solutions" className="dropdown--solutions">
							<div>
								<div className="uptitle text-xxsmall text-extraLight u-mb1">
									Communication
								</div>

								<NavDropdownItemWithIcon
									icon={<IconApi height={18} />}
									label="Email sending API"
									description="— integrate email sending into your application."
									url="/email-sending-api/"
								/>

								<NavDropdownItemWithIcon
									icon={<IconSipka height={21} />}
									label="Messenger"
									description="— send product updates and newsletters."
									url="/targeted-email-messages/"
								/>

								<NavDropdownItemWithIcon
									icon={<IconRaketa height={22} />}
									label="Email automation"
									description="— set up automated email sequences."
									url="/automated-email-sequences/"
								/>

								<div className="uptitle text-xxsmall text-extraLight u-mt3 u-mb1">
									Audience
								</div>

								<NavDropdownItemWithIcon
									icon={<IconsZavinac height={20} />}
									label="Subscribe forms"
									description="— collect email addresses."
									url="/subscribe-forms/"
								/>

								<NavDropdownItemWithIcon
									icon={<IconPanak height={22} />}
									label="Contact profiles"
									description="— manage all your subscribers in one place."
									url="/contact-profiles/"
								/>

								<div className="uptitle text-xxsmall text-extraLight u-mt3 u-mb1">
									Development
								</div>

								<NavDropdownItemWithIcon
									icon={<IconEditor height={25} />}
									label="No-code email editor"
									description="— create reusable email templates."
									url="/no-code-email-editor/"
								/>

								<NavDropdownItemWithIcon
									icon={<IconEmail height={19} />}
									label="Transactional email templates"
									description="— use premade email templates."
									url="/transactional-email-templates/"
								/>
							</div>
						</NavDropdown>

						<Link className="nav-link" to="/pricing/">
							<span className="text-small text-mediumDark nav-linkLabel">
								<span>Pricing</span>
							</span>
						</Link>

						<Link className="nav-link" to="/customer-reviews/">
							<span className="text-small text-mediumDark nav-linkLabel">
								<span>Customer reviews</span>
							</span>
						</Link>

						<Link className="nav-link" to="/docs/email-sending-quickstart/">
							<span className="text-small text-mediumDark nav-linkLabel">
								<span>Developer docs</span>
								<IconArrowRight />
							</span>
						</Link>
					</div>

					<div className="nav-actions">
						{user && (
							<>
								<a
									className="header-login button button--bright button--outline"
									href="https://client.sidemail.io">
									<span style={{ marginRight: 10 }}>Dashboard</span>
									<IconArrowRight width={12} />
								</a>
							</>
						)}

						{!user && (
							<>
								<a
									className="header-login button button--bright button--outline"
									href="https://client.sidemail.io/login">
									Login
								</a>

								<a
									className="header-signup"
									href="https://client.sidemail.io/register">
									Try it free
								</a>
							</>
						)}
					</div>

					<MobileNav />
				</nav>
			</div>
		</header>
	);
};

export default Header;
